import vueProductSvc from '@services/vueProductSvc';
import vuePreOrderSvc from '@services/vuePreOrderSvc';
import { getPerfectPairPrices } from "@services/vueCartSvc";
import { getObjProperty, isNum, getObjPropertyCatchUndefined } from "@utilities/mrVueUtils";
import { isPerfectPairEligible } from "@/../../mr_modules/shareableFunctions/lib/advisor";
import vueSubscriptionSvc from "@services/vueSubscriptionSvc";
import { getDoorDashEligible } from '@services/vueDoorDashSvc';
import { isObject } from "@utilities/mrVueUtils";
import segmentTracking from '@utilities/segmentTracking';

const state = {
  version: 'A',
  product: {},
  productsForProductType: [],
  purchaseType: 'subscription',
  qty: 1,
  subFrequency: 6,
  skipPurchasePanel: false,
  extraTube: false,
  attachableProdIds: [],
  tones: {
    'G': 'gold',
    'M': 'mahogany',
    'C': 'copper',
    'R': 'red',
    'A': 'ash'
  },
  toneMap: {
    'gold': 'Golden',
    'mahogany': 'Auburn',
    'copper': 'Copper',
    'red': 'Red',
    'ash': 'Smoky'
  },
  fetchingPerfectPairPrices: false,
  addPerfectPair: false,
  perfectPairSubPrice: null,
  perfectPairOneTimePrice: null,
  preOrderId: null,
  subscription: {},
  selectedPerfectPairGloss: null,
  selectedPerfectPairRCC: null,
  showPriceModule: false,
  doorDashEligible: false,
};

export const getters = {
  alreadySubscribedToSelectedPerfectPairRCC(state) {
    const { selectedPerfectPairRCC, subscription } = state;
    if (!selectedPerfectPairRCC || !subscription) {
      return false;
    }

    return getObjProperty(subscription, 'product.code') === getObjProperty(selectedPerfectPairRCC, 'code');
  },
  alreadySubscribedToSelectedPerfectPairGloss(state) {
    const { selectedPerfectPairGloss, subscription } = state;
    if (!selectedPerfectPairGloss || !subscription) {
      return false;
    }

    return getObjProperty(subscription, 'product.code') === getObjProperty(selectedPerfectPairGloss, 'code');
  },

  perfectPairGloss(state, getters, rootState, rootGetters) {
    if (state.selectedPerfectPairGloss) {
      return state.selectedPerfectPairGloss;
    }

    const xsellGloss = rootGetters['xsell/xsellGloss'];

    if (!xsellGloss || xsellGloss.product_type !== 'gloss') {
      return null;
    }

    return xsellGloss;
  },

  perfectPairGlossFromPrice(state) {
    if (!state.perfectPairSubPrice || !state.product.sub_price) {
      return;
    }

    return state.perfectPairSubPrice - state.product.sub_price;
  },

  perfectPairGlossOneTimeFromPrice(state) {
    if (!state.perfectPairOneTimePrice || !state.product.price) {
      return;
    }

    return state.perfectPairOneTimePrice - state.product.price;
  },

  isRCC(state) {
    return state.product && state.product.product_type == 'color_kit';
  },

  isPurePigment(state) {
    let pp = getObjProperty(state, 'product.includesPurePigment');
    return !!pp && pp != 'NONE';
  },

  purePigmentTone(state) {
    let s = getObjProperty(state, 'product.includesPurePigment');
    if (!!s && typeof s == 'string') {
      return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
    } else {
      return '';
    }
  },

  subFrequencies(state) {
    if (!state.product.product_type_sub_frequencies) {
      return [];
    }

    return state.product.product_type_sub_frequencies.split(",");
  },

  reviewInfoAvailable: (state) => (infoKey) => {
    let reviewInfo = getObjProperty(state, 'product.reviewInfo') || {};
    let total = 0;

    if (reviewInfo[infoKey]) {
      Object.keys(reviewInfo[infoKey]).forEach(key => {
        total += reviewInfo[infoKey][key];
      });
    }

    return total > 30;
  },

  displayTwoPrices(state) {
    let display = false;

    if (state.product.renewable) {
      display = Boolean((state.product.price - state.product.sub_price) > 0);
    }

    return display;
  },

  carouselImages(state) {
    return getObjPropertyCatchUndefined(state.product, 'imagery.pdpCarouselLarge') || [];
  },

  perfectPairImages(state) {
    return getObjPropertyCatchUndefined(state.selectedPerfectPairGloss, 'imagery.perfectPairCarousel') || [];
  },

  perfectPairEligible(state, getters, rootState) {
    if (!state.product.id || !getters.perfectPairGloss || !Object.keys(rootState.colorAdvisor.answers).length) {
      return false;
    }
    //- everyone who lands on Perfect Pair PDP is eligible to see the prices and add PP to cart
    //- for Pure Pigment shades only additional requirement is the answer to comfort_level
    let onPerfectPairPdp = getObjProperty(rootState, 'cms.cmsSettings.perfectPair');
    if (onPerfectPairPdp || (getters.isPurePigment && getObjProperty(rootState, 'colorAdvisor.answers.comfort_level') == 'not_comfortable')) {
      return true;
    }

    return isPerfectPairEligible(rootState.colorAdvisor.answers, [state.product, getters.perfectPairGloss]);
  },

  showPerfectPairOnPdp(state, getters, rootState) {
    let show = false;
    let cdata = rootState.customer.cdata;

    if (rootState.cms.cmsSettings.showPerfectPairRoutine && cdata) {
      const comfortLevel = rootState.colorAdvisor.answers.comfort_level;
      const colorInSalon = 'at_salon' === rootState.colorAdvisor.answers.color_where;
      const includeSomewhatComfortableInPerfectPair = rootState.cms.cmsSettings.includeSomewhatComfortableInPerfectPair;
      const meetsAdvisorRequirements = colorInSalon || (comfortLevel == 'not_comfortable' || (includeSomewhatComfortableInPerfectPair && comfortLevel == 'somewhat_comfortable'));
      const customerHasNoOrders = !cdata.order_total || cdata.order_total == 0;

      if (customerHasNoOrders && meetsAdvisorRequirements) {
        show = getters.perfectPairEligible;
      }
    }
    return show;
  },

  hasActiveSub(state) {
    return Boolean(getObjProperty(state.subscription, 'id'));
  },

  membershipBenefits(state) {
    let benefits = [];

    if (state.product) {
      if (state.product.membershipBenefits) {
        benefits = benefits.concat(state.product.membershipBenefits);
      }

      if (state.product.oneTimeBenefits) {
        benefits = benefits.concat(state.product.oneTimeBenefits);
      }
    }

    return benefits;
  },

  misterTheme(state) {
    return state.product && state.product.misterProduct;
  },

  qualifiedFeaturedVariantId: (state, getters, rootState, rootGetters) => (id) => {
    return Boolean(id) && isNum(id) && state.product.id != id && !rootGetters['cart/productIdInCart'](id) && !rootGetters['customer/productIdInPurchaseList'](id);
  },

  qualifiedFeaturedProductType: (state, getters, rootState, rootGetters) => (productType) => {
    let suggestedAddons = rootGetters['cart/suggestedAddons'] || [];
    return Boolean(productType) && productType.length && state.product.product_type != productType && suggestedAddons.some(item => item.productType == productType);
  },

  featuredProduct(state, getters, rootState) {
    //- select featured product in this order:
    //- featuredProduct.variantId -> featuredProduct.product -> globalFeaturedProduct.variantId -> globalFeaturedProduct.product
    //- each one should be checked if in cart or already purchased if so go to next based on the order above and check again
    //- when looking up by product we check if that product is suggested in cart xsell (suggestedAddons)
    let featured = {
      type: null,
      value: null,
      modalTitle: null
    };

    //- first look on the product for variantId and make sure it's not already in the cart
    let featuredVariantId = getObjProperty(state, 'product.featuredProduct.variantId');
    let featuredProduct = getObjProperty(state, 'product.featuredProduct.product');

    if (getters.qualifiedFeaturedVariantId(featuredVariantId)) {
      featured.type = 'id';
      featured.value = featuredVariantId;
      featured.modalTitle = getObjProperty(state, 'product.featuredProduct.variantXsellTitle');

    } else if (getters.qualifiedFeaturedProductType(featuredProduct)) { //- fall back to product ("gloss", "root_touch_up")
      //- use the product from xsell (product gets removed from xsell if its in the cart)
      featured.type = 'product';
      featured.value = featuredProduct;
      featured.modalTitle = getObjProperty(state, 'product.featuredProduct.productXsellTitle');

    } else if (rootState.systemSettings.globalFeaturedProduct) { //- next go to globalFeatured product from systemSettings
      let globalFeaturedVariantId = rootState.systemSettings.globalFeaturedProduct.variantId;
      let globalFeaturedProduct = rootState.systemSettings.globalFeaturedProduct.product;

      if (getters.qualifiedFeaturedVariantId(globalFeaturedVariantId)) { //- make sure variantId is not already in the cart, previously purchased or is current product
        featured.type = 'id';
        featured.value = globalFeaturedVariantId;
        featured.modalTitle = rootState.systemSettings.globalFeaturedProduct.variantXsellTitle;

      } else if (getters.qualifiedFeaturedProductType(globalFeaturedProduct)) {
        featured.type = 'product';
        featured.value = globalFeaturedProduct;
        featured.modalTitle = rootState.systemSettings.globalFeaturedProduct.productXsellTitle;
      }
    }

    return featured;
  },

  pdpDataLoaded(state, getters, rootState) {
    return !!(state.product && state.product.code && rootState.customer.cdata && rootState.colorAdvisor.advisorLoaded);
  },

  changeText(state) {
    let text = 'color';
    if (state.product.product_type === 'boosts') {
      text = 'boost';
    }
    return text;
  }
};

export const actions = {
  getDoorDashEligible({ dispatch, commit, rootState, state }) {
    const { product } = state;
    const cdata = getObjProperty(rootState, 'customer.cdata') || {};

    if (!product) {
      return;
    }

    const { locationContext } = cdata;

    if (!isObject(locationContext)) {
      return;
    }

    getDoorDashEligible({
      products: [product.code],
      locationContext,
    }).then(({ data }) => {
      commit('setDoorDashEligible', !!data[product.code]);
    }).catch((err) => {
      dispatch('notifyError', { err, silent: true }, { root: true });
    });
  },

  getProductByUrlKey({ commit, dispatch, rootState }, urlKey) {
    return new Promise((resolve, reject) => {
      vueProductSvc.getProductByUrlKey({ urlKey }).then(res => {
        let product = res.data || {};

        if (product.grayCoverageGauranteed) {
          product.prodInfo.push(product.grayCoverageText);
        } else if (product.noGrayCoverage) {
          product.prodInfo.push(product.noGrayCoverageText);
        }

        if (rootState.cms.cmsSettings.perfectPair) {
          commit('setSelectedPerfectPairRCC', product);
        }

        commit('setProduct', product);

        if (!res.data.id) {
          return reject();
        }

        if (res.data.groupByProductType) {
          dispatch('getProductsForProductType', res.data.product_type);
        }

        resolve();
      }).catch(reject);
    });
  },

  getProductsForProductType({ commit }, productType) {
    return new Promise((resolve, reject) => {
      vueProductSvc.getActiveVisibleProductsByType({ productType }).then(res => {
        let sortedProducts = res.data.sort((a, b) => a.xsell_rank - b.xsell_rank);
        commit('setProductsForProductType', sortedProducts);
        resolve();
      }).catch(reject);
    });
  },

  openPlaygroundModalWTrackEvent({ dispatch }, params) {
    const { event, ...restParams } = params;
    segmentTracking.trackMREvent(event);
    dispatch('openPlaygroundModal', restParams);
  },

  openPlaygroundModal({ dispatch, rootState }, params) {
    let experiments = getObjProperty(rootState, 'global.experiments');
    let experimentVersion = experiments['Playground Redesign 2023'] || 'A';
    let theme = experimentVersion == 'A' ? 'try-it-on-modal' : 'try-it-on-modal-v2';
    let modalParams = {
      ...(params ?? {}),
      component: 'PdpPlaygroundModal',
      theme: theme,
    };
    dispatch('modal/showModal', modalParams, { root: true });
  },

  openPurchasePanelModal({ dispatch }, params) {
    let { theme, props, returnFocusElement } = params;
    dispatch('modal/showModal', {
      component: 'PurchasePanelV2Modal',
      theme,
      returnFocusElement,
      props,
    }, { root: true });
  },

  openPurchasePanelAttachModal({ dispatch }, params) {
    dispatch('modal/showModal', {
      component: 'PurchasePanelAttachModal',
      theme: 'mobile-drawer',
      returnFocusElement: params.returnFocusElement,
      onClose: params.onClose,
      props: {
        paramProduct: params.product // used on perfect pair pdp
      }
    }, { root: true });
  },

  getPerfectPairPrices({ state, getters, commit, rootState }) {
    commit('setFetchingPerfectPairPrices', true);

    const perfectPairGlossId = getObjProperty(getters, 'perfectPairGloss.id');
    let payload = {
      productIds: [state.product.id, perfectPairGlossId],
      subPromoIds: [rootState.constants.perfectPairSubPromoIdOne],
      oneTimePromoIds: [rootState.constants.perfectPairOneTimePromo]
    };

    return getPerfectPairPrices(payload).then(res => {
      commit('setPerfectPairSubPrice', res.data.subPrice);
      commit('setPerfectPairOneTimePrice', res.data.oneTimePrice);
      commit('setFetchingPerfectPairPrices', false);
    }).catch(() => commit('setFetchingPerfectPairPrices', false));
  },

  getPreOrderId({ state, commit }) {
    let payload = { productId: state.product.id };

    vuePreOrderSvc.getPreOrderId(payload).then((res) => {
      commit('setPreOrderId', res.data.id);
    });
  },

  cancelPreOrder({ state, commit, dispatch }) {
    let payload = { preOrderId: state.preOrderId };

    vuePreOrderSvc.cancelPreOrder(payload).then(() => {
      commit('setPreOrderId', null);
    }).catch(() => {
      dispatch('notifyError');
    });
  },

  getCustomerNextSubByProductType({ commit }, params) {
    let { productType } = params;
    vueSubscriptionSvc.getCustomerNextSubByProductType({ productType }).then(res => {
      commit('setSubscription', res.data);
    });
  },

  openLimitlessPlusModal({ dispatch }, params) {
    dispatch('modal/showModal', {
      component: 'LimitlessPlusModal',
      theme: 'full-screen',
      props: params ? params.props : null
    }, { root: true });
  },

  openPdpXsell({ getters, dispatch, rootState, rootGetters }) {
    if (rootState.cms.cmsSettings.suppressPurchasePanelXsell) {
      return window.location = '/cart';
    }

    if (getters.featuredProduct.value && getters.featuredProduct.type == 'id') {
      return dispatch('xsell/getProProduct', getters.featuredProduct.value, { root: true }).then(() => {
        dispatch('xsell/openPdpXsellCombinedModal', getters.featuredProduct.modalTitle, { root: true });
      });
    } else if (rootGetters['cart/suggestedAddons'].length > 0) {
      return dispatch('xsell/openPdpXsellCombinedModal', null, { root: true });
    } else {
      return window.location = '/cart';
    }
  },

  openKeepShoppingModal({ getters, commit, dispatch, rootState, rootGetters }) {
    dispatch('modal/showModal', {
      component: 'KeepShoppingModal',
      theme: 'mobile-drawer keep-shopping-modal',
    }, { root: true });

    if (rootState.cms.cmsSettings.suppressPurchasePanelXsell) {
      return window.location = '/cart';
    }

    if (getters.featuredProduct.value) {
      if (getters.featuredProduct.type == 'id') {
        return dispatch('xsell/getProProduct', getters.featuredProduct.value, { root: true }).then(() => {
          dispatch('modal/showModal', {
            component: 'KeepShoppingModal',
            theme: 'mobile-drawer keep-shopping-modal',
          }, { root: true });
        });
      } else if (getters.featuredProduct.type == 'product') {
        let suggestedAddons = rootGetters['cart/suggestedAddons'] || [];
        let product = suggestedAddons.find(addon => addon.productType == getters.featuredProduct.value);
        commit('xsell/setProProduct', product, { root: true });
      }
    }

    if (rootGetters['cart/suggestedAddons'].length > 0) {
      return dispatch('modal/showModal', {
        component: 'KeepShoppingModal',
        theme: 'mobile-drawer keep-shopping-modal',
        disableClose: true
      }, { root: true });
    } else {
      return window.location = '/cart';
    }
  }
};

export const mutations = {
  setVersion(state, val) {
    state.version = val || 'A';
  },

  setDoorDashEligible(state, val) {
    state.doorDashEligible = !!val;
  },

  setProduct(state, val) {
    state.product = val || {};
  },

  setProductsForProductType(state, val) {
    state.productsForProductType = val;
  },

  setAttachableProdId(state, id) {
    if (!id || typeof +id != "number") {
      return;
    }

    id = +id;
    let arr = state.attachableProdIds;

    if (!arr.includes(id)) {
      state.attachableProdIds.push(id);
    }
  },

  clearAttachableProdIds(state) {
    state.attachableProdIds = [];
  },

  setAddPerfectPair(state, value) {
    state.addPerfectPair = !!value;
  },

  setSelectedPerfectPairRCC(state, value) {
    state.selectedPerfectPairRCC = value;
  },

  setFetchingPerfectPairPrices(state, value) {
    state.fetchingPerfectPairPrices = !!value;
  },

  setPerfectPairSubPrice(state, val) {
    state.perfectPairSubPrice = val;
  },

  setPerfectPairOneTimePrice(state, val) {
    state.perfectPairOneTimePrice = val;
  },

  setPurchaseType(state, val) {
    state.purchaseType = val;
  },

  setQty(state, val) {
    state.qty = +val;
  },

  setSubFrequency(state, val) {
    state.subFrequency = +val;
  },

  setSkipPurchasePanel(state, val) {
    state.skipPurchasePanel = !!val;
  },

  setExtraTube(state, val) {
    state.extraTube = !!val;
  },

  setPreOrderId(state, val) {
    state.preOrderId = val;
  },

  setSubscription(state, val = {}) {
    state.subscription = val;
  },

  setShowPriceModule(state, val) {
    state.showPriceModule = Boolean(val);
  },

  setSelectedPerfectPairGloss(state, value) {
    const { product_type } = value || {};
    if (product_type !== 'gloss') {
      return;
    }
    state.selectedPerfectPairGloss = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};