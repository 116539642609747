<template lang="pug">
  .mr-dropdown(:class="{expanded: expanded, disabled: disabled, invalid: v.$error }" v-click-outside="closeSelect")
    .dropdown-wrap-base(:class="dropdownWrapClasses")
      button.select.btn-reset(type="button" ref="select" @click="toggleSelect" @keydown.down.prevent="selectDown()" @keydown.enter.prevent="selectDown()" @keydown="search" :aria-expanded="expanded ? 'true' : 'false'" :class="buttonClasses" :disabled="disabled")
        span.label(v-if="label" :id="_uid") {{ label }}
        span.label(v-else-if="!newTheme" :id="_uid") Select:&ensp;
        span.hide(:id="_uid" :data-label-id="_uid")
        span.value {{ optionLabel }}
        span.ada-tooltip(v-if="selectedOption") selected
        .arrow

      .options(
        :aria-hidden="!expanded"
        role="listbox"
        :aria-labelledby="_uid"
        body-scroll-lock-ignore
        :class="{ fixed: fixed }"
        ref="options"
        tabindex="-1"
      )
        button.option.btn-reset(type="button" v-for="(option, index) in options" :key="option.value" :ref="'option'" role="option" :aria-selected="optionSelected(option)" @click="selectChangeAndClose(option, $event)" :data-mr-ass="option.value" @keydown.up.prevent="focusUp(index)" @keydown.down.prevent="focusDown(index)" @keydown.enter.prevent="selectChangeAndClose(option)" @keydown="search" :tabindex="expanded ? 0 : -1" :class="{selected: optionSelected(option)}") {{option.label}}

    transition(name="fade")
      .input-error.xs-f-xsmall.lg-f-xxsmall.max-at-tweak(v-if="inputError")
        i.icon-warning
        | {{ inputError }}

    MrHiddenAutoCompleteInput(
      v-if="autocomplete"
      :autocomplete="autocomplete"
      @value="autocompleteChange"
    )

</template>

<script>
  import MrIcon from '@components/MrIcon';
  let resetKeysSoFarTimer;

  export default {
    name: 'MrDropdown',

    components: {
      MrHiddenAutoCompleteInput: () => import('@components/MrHiddenAutoCompleteInput'),
      MrIcon,
    },

    inject: ['getObjProperty'],

    props: {
      newTheme: {
        type: Boolean,
        required: false,
        default: false
      },
      label: {
        type: String,
        default: ''
      },
      options: {
        type: Array,
        default() {
          [];
        }
      },
      /** Static prop, does not support reactivity */
      fixed: {
        type: Boolean,
        default: false,
      },
      value: {
        type: null,
        default: null,
      },
      disabled: {
        type: Boolean,
        default: false
      },
      v: {
        type: Object,
        default: () => ({}),
      },
    },

    data: () => {
      return {
        keysSoFar: '',
        expanded: false,
        selectedOption: null
      };
    },

    computed: {
      dropdownWrapClasses() {
        return {
          'dropdown-wrap-new-theme': this.newTheme,
          'dropdown-wrap': !this.newTheme,
        };
      },

      buttonClasses() {
        if (this.newTheme) {
          return {};
        }
        return {
          'has-value': !!this.selectedOption, disabled: this.disabled
        };
      },

      optionLabel() {
        let label = null;

        if (this.value !== null & this.value != undefined && this.options && this.options.length) {
          for (var x = 0; x < this.options.length; x++) {
            if (this.value == this.options[x].value) {
              label = this.options[x].label;
              break;
            }
          }
        }

        return label;
      },

      inputError() {
        var error = null;
        if (!this.v.$params) {
          return error;
        }

        if (this.v.$params.required && this.v.$error && !this.v.$model) {
          error = `This field is required.`;
        }
        return error;
      },

      autocomplete() {
        return this.$attrs.autocomplete;
      }
    },

    watch: {
      value() {
        this.checkForSelectedOption();
      },
    },

    mounted() {
      this.checkForSelectedOption();
      if (this.fixed) {
        this.resizeOptions();
        window.onresize = () => {
          this.resizeOptions();
        };
      }
    },

    methods: {
      optionSelected(option) {
        return !!(option && this.value && this.value == option.value);
      },

      toggleSelect(event) {
        event.stopPropagation();
        this.expanded = !this.expanded;
      },

      closeSelect() {
        this.expanded = false;
      },

      selectDown() {
        this.expanded = true;
        this.$refs['option'][0].focus();
      },

      focusUp(index) {
        if (index - 1 >= 0) {
          this.$refs.option[index - 1].focus();
        }
        if (index - 1 == -1) {
          this.$refs.select.focus();
        }
      },

      focusDown(index) {
        if (this.options[index + 1]) {
          this.$refs.option[index + 1].focus();
        }
      },

      focusOn(index) {
        if (this.options[index]) {
          this.$refs.option[index][0].focus();
        }
      },

      selectChange(option, event) {
        if (event) {
          event.stopPropagation();
        }
        if (option) {
          this.selectedOption = option;
          this.$emit('input', option.value);
        }
      },

      selectChangeAndClose(option, event) {
        this.selectChange(option, event);
        this.expanded = false;
        this.$refs.select.focus();
      },

      // https://markus.oberlehner.net/blog/accessible-custom-vue-form-select-component-simple-but-advanced/
      search(e) {
        clearTimeout(resetKeysSoFarTimer);
        // No alphanumeric key was pressed.
        if (e.key.length > 1) {
          return;
        }

        resetKeysSoFarTimer = setTimeout(() => {
          this.keysSoFar = '';
        }, 600);

        this.keysSoFar += e.key;


        let matchingOptionIndex = this.options.findIndex(x => {
          if (x.label) {
            return ('' + x.label).toLowerCase().startsWith(this.keysSoFar);
          }
        });
        let matchingOption = this.options[matchingOptionIndex];

        // Return if no matching option
        if (!matchingOption) {
          return;
        }

        this.$emit('change', matchingOption);
        this.selectChange(matchingOption);
        const scrollIntoView = this.getObjProperty(this, '$refs.option[' + matchingOptionIndex + '].scrollIntoView');
        if (scrollIntoView) {
          scrollIntoView.call(this.$refs.option[matchingOptionIndex]);
        }
        const focus = this.getObjProperty(this, '$refs.option[' + matchingOptionIndex + '].focus');
        if (focus) {
          focus.call(this.$refs.option[matchingOptionIndex]);
        }
      },

      checkForSelectedOption() {
        if (this.options && this.options.length && this.value !== null & this.value != undefined) {
          this.options.forEach(option => {
            if (option.value == this.value) {
              this.selectedOption = option;
            }
          });
        } else {
          this.selectedOption = null;
        }
      },

      autocompleteChange(value) {
        if (!this.value) {
          this.$emit('input', value);
        }
      },
      resizeOptions() {
        this.$refs.options.style.width = this.$refs.select.offsetWidth + "px";
      }
    },
  };
</script>

<style scoped lang='stylus'>
  .mr-dropdown
    display inline-block
    vertical-align top
    height 3em
    overflow visible
    z-index initial

    .dropdown-wrap-base
      position relative
      border 1px solid ui-color-6
      background-color color-white
      font-size initial
      z-index 10
      transition(z-index 400ms step-end)

    .dropdown-wrap
      border-radius 3px

    .dropdown-wrap-new-theme
      border-radius 8px

    .select
      position relative
      padding 8.5px
      padding-right 2.5em
      width 100%
      text-align left

      .label
        color #999
        font-size 14px

      .arrow
        position absolute
        border 0.4em solid transparent
        border-top 0.5em solid color-slate
        width 0
        height 0
        right 0.8em
        top 50%
        margin-top -0.25em

      &.has-value
        padding-top 13px
        padding-bottom 4px

        .label
          position absolute
          font-size 0.7em
          top 0
          left 0.66em
          line-height 2em

    .options
      max-height 0
      transition(max-height 400ms ease-in-out)
      overflow auto

      &.fixed
        position fixed
        background-color #fff
        border-bottom 1px solid ui-color-6
        border-left 1px solid ui-color-6
        border-right 1px solid ui-color-6
        border-radius 3px

      .option
        display block
        padding 0.333em
        padding-left 0.666em
        padding-right 2.5em
        width 100%
        text-align left

        &:last-child
          padding-bottom 1em

        &.selected
          background-color color-gray-light

    &.expanded
      .dropdown-wrap-base
        z-index 9
        transition(z-index 400ms step-start)

      .select
        .arrow
          border 0.4em solid transparent
          border-bottom 0.5em solid color-slate
          margin-top -0.65em

      .options
        max-height 20em

    &.disabled
      color text-color-2
      background-color color-gray-light

      .dropdown-wrap-base
        background-color color-gray-light

      .select
        color color-gray

        .label
          color color-gray

        .arrow
          border-top 0.5em solid color-gray

    &.invalid
      .dropdown-wrap-base
        border-color color-attention

</style>
