export default {
  name: "StoreValue",

  props: {
    storeName: {
      required: true,
      type: String
    },

    accessPoint: {
      required: true,
      type: String,
      validator: (value) => {
        const validProps = ['state', 'getters', 'actions', '_actions', 'mutations'];
        const valid = validProps.includes(value);

        return valid;
      }
    },

    propName: {
      required: true,
      type: String
    }
  },

  render() {
    if (this.accessPoint === 'state') {
      return this.$scopedSlots.default({
        value: this.$store[this.accessPoint][this.storeName][this.propName]
      });
    } else {
      return this.$scopedSlots.default({
        value: this.$store[this.accessPoint][`${this.storeName}/${this.propName}`]
      });
    }
  },
};