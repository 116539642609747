import {
  getLocation,
  getLocationReviews,
  getClosestLocationsByLatLong,
  getClosestLocationsByAddress,
  getClosestLocationsByCustomerAddress,
  getClosestLocationsByIp,
  getClosestLocationsByLocationId,
} from '../../services/vueColorbarSvc';
import { getAppointmentsData } from '../../services/vueCustomerSvc';
import { getObjProperty } from '@utilities/mrVueUtils';

export const state = {
  location: {},
  locationReviews: null,
  locationLoading: true,
  upcomingAppointment: null,
  lastAppointment: null,
  initializingBopis: false,
  closestLocations: [],
  locationsWithStock: [],
  locationsOutOfStock: [],
  selectedBopisLocation: null,
  pickItUpSubsId: null,
  showBopis: true,
};

export const getters = {
  locationId(state) {
    return getObjProperty(state, 'location._id');
  },

  availableAtColorbar: (state) => (productCode) => {
    if (!state.location.productCodesOffered || !state.location.region || !state.location.region.metadata || !state.location.region.metadata.enableBOPIS) {
      return false;
    }
    return state.location.productCodesOffered.includes(productCode);
  },

  region(state) {
    return state.location.region || {};
  },

  selectedLocationUrmPrice(state) {
    // support older versions and cms
    return getObjProperty(state, 'location.membershipPrice');
  },

  selectedLimitlessProPrice(state) {
    return getObjProperty(state, 'location.membershipPrices.pro');
  },

  selectedLimitlessProPlusPrice(state) {
    return getObjProperty(state, 'location.membershipPrices.proPlus');
  },

  downgradePriceDifference(state, getters) {
    return getters.selectedLimitlessProPlusPrice - getters.selectedLimitlessProPrice;
  },

  servicePackage: (state) => {
    return getObjProperty(state, `location.servicePackages`);
  },

  selectedBopisLocation(state) {
    return state.selectedBopisLocation;
  },

  noBopisLocationWithStock(state) {
    return !state.initializingBopis && state.locationsWithStock.length == 0;
  },

  hasFounderOffer(state) {
    if (!state.location || !state.location.openingDate) {
      return false;
    }
    let now = +new Date();
    let openingDate = +new Date(state.location.openingDate);

    return (now - openingDate) < (1000 * 60 * 60 * 24 * 60);
  }
};

export const actions = {
  async getLocationReviews({ commit }, code) {
    commit('setLocationReviews', null);
    const { data: reviews } = await getLocationReviews({ code });
    commit('setLocationReviews', reviews);
  },

  initNextAndLastAppointmentData({ state, dispatch }) {
    if (state.upcomingAppointment || state.lastAppointment) {
      return;
    }

    dispatch('getCustomerAppointmentsData');
  },

  loadLocation({ commit }, locationCode) {
    return new Promise((resolve, reject) => {
      if (!locationCode) {
        return reject('missing param - locationCode');
      }

      commit('setLocationLoading', true);
      getLocation({
        code: locationCode
      }).then(({ data }) => {
        commit('setLocation', data);
        commit('setLocationLoading', false);
        resolve();
      }).catch(err => {
        commit('setLocationLoading', false);
        reject(err);
      });
    });
  },

  getCustomerAppointmentsData({ commit }) {
    getAppointmentsData().then(res => {
      let upcoming = res.data.upcomingAppointment;
      let last = res.data.lastAppointment;
      if (upcoming?.appointment?.addOnTreatments) {
        upcoming.appointment?.addOnTreatments?.map((t) => {
          upcoming.appointment.serviceName = upcoming.appointment.serviceName + ` + ${t.name}`;
        });
      }

      if (upcoming) {
        commit('setUpcomingAppointment', upcoming);
      }

      if (last) {
        commit('setLastAppointment', last);
      }
    });
  },

  cleanBopis({ commit }) {
    commit('setSelectedBopisLocation', null);
    commit('setClosestLocations', []);
    commit('setLocationsWithStock', []);
    commit('setLocationsOutOfStock', []);
    commit('setPickItUpSubsId', null);
  },

  initializeBopis({ dispatch, commit, rootState }, params) {
    dispatch('cleanBopis');
    commit('setInitializingBopis', true);

    if (rootState.cart.cart && rootState.cart.cart.locationId) {
      const subscriptionId = params ? params.subscriptionId : undefined;
      dispatch('getClosestLocationsByLocationId', { locationId: rootState.cart.cart.locationId, subscriptionId });
    } else if (rootState.customer.info.authed) {

      dispatch('getClosestLocationsByCustomerAddress', params)
        .catch(() => {
          dispatch('getClosestLocationByGeolocation', params)
            .catch(() => {
              dispatch('getClosestLocationsByIp', params);
            });
        });
    } else {
      dispatch('getClosestLocationByGeolocation', params)
        .catch(() => {
          dispatch('getClosestLocationsByIp', params);
        });
    }
  },

  getClosestLocationByGeolocation({ dispatch, commit }, params) {

    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {

          let { latitude, longitude } = position.coords;

          getClosestLocationsByLatLong({ latitude, longitude })
            .then(res => {
              if (!res.data || res.data.length === 0) {
                return reject();
              }
              commit('setClosestLocations', res.data);
              dispatch('closestAvailableLocations', params);
              resolve();
            })
            .catch(reject);
        }, reject, { timeout: 3000 });
      } else {
        reject();
      }
    });
  },

  getClosestLocationsByAddress(_, params) {
    return getClosestLocationsByAddress(params);
  },

  getClosestLocationsByCustomerAddress({ dispatch, commit }, params) {
    return new Promise((resolve, reject) => {
      getClosestLocationsByCustomerAddress()
        .then(res => {

          if (!res.data || res.data.length === 0) {
            return reject();
          }
          commit('setClosestLocations', res.data);
          dispatch('closestAvailableLocations', params);
          resolve();
        })
        .catch(reject);
    });
  },

  getClosestLocationsByIp({ dispatch, commit }, params) {
    return new Promise((resolve, reject) => {
      getClosestLocationsByIp().then(res => {
        if (!res.data) {
          commit('setInitializingBopis', false);
          return reject();
        }

        commit('setClosestLocations', res.data);
        dispatch('closestAvailableLocations', params);
        resolve();
      }).catch(() => {
        commit('setInitializingBopis', false);
        reject();
      });
    });
  },

  getClosestLocationsByLocationId({ dispatch, commit }, params) {
    return new Promise((resolve, reject) => {
      getClosestLocationsByLocationId(params)
        .then(res => {
          if (res.data) {
            commit('setClosestLocations', res.data);
          }
          dispatch('closestAvailableLocations', params);
          resolve();
        })
        .catch(reject);
    });
  },

  closestAvailableLocations({ dispatch, state, rootState, commit }, params) {
    return new Promise((resolve) => {
      if (state.closestLocations[0]) {
        if (!state.initializingBopis) {
          commit('setInitializingBopis', true);
        }
        const regionId = state.closestLocations[0].region_id;
        const action = params && params.subscriptionId ? 'subscriptions/getAvailabilityByRegionForSubscription' : 'cart/getAvailabilityByRegion';
        const subscriptionId = params && params.subscriptionId;
        dispatch(action, { subscriptionId, regionId }, { root: true })
          .then(() => {
            const availableLocations = rootState.cart.availableLocations;
            const locationsWithStock = [], locationsOutOfStock = [];
            const cartLocation = rootState.cart.cart && rootState.cart.cart.locationId ? rootState.cart.cart.locationId : null;
            let bopisLocation = null;
            state.closestLocations.forEach(cloc => {

              availableLocations.forEach(aloc => {
                if (cloc.code === aloc.code) {
                  cloc.inStock = aloc.inStock;
                  cloc.enableBOPIS = aloc.enableBOPIS;

                  if (cartLocation === cloc._id) {
                    bopisLocation = cloc;
                  }
                }
              });
              if (cloc.enableBOPIS) {
                if (!cloc.inStock) {
                  locationsOutOfStock.push(cloc);
                } else {
                  locationsWithStock.push(cloc);
                }
              }
            });

            if (!bopisLocation && locationsWithStock.length) {
              bopisLocation = locationsWithStock[0];
            }

            commit('setSelectedBopisLocation', bopisLocation ? bopisLocation : {});
            commit('setLocationsWithStock', locationsWithStock);
            commit('setLocationsOutOfStock', locationsOutOfStock);
            commit('setInitializingBopis', false);
            commit('setPickItUpSubsId', null);
            resolve();
          });
      } else {
        commit('setInitializingBopis', false);
        resolve();
      }
    });
  },

  pickItUpSubs({ commit }, params) {
    commit('setPickItUpSubsId', params);
  },

};

export const mutations = {
  setLocationReviews(state, reviews) {
    state.locationReviews = reviews;
  },

  setLocation(state, val) {
    state.location = val;
  },

  setLocationLoading(state, val) {
    state.locationLoading = Boolean(val);
  },

  setUpcomingAppointment(state, val) {
    state.upcomingAppointment = val;
  },

  setLastAppointment(state, val) {
    state.lastAppointment = val;
  },

  setClosestLocations(state, val) {
    state.closestLocations = val;
  },

  setLocationsWithStock(state, val) {
    state.locationsWithStock = val;
  },

  setLocationsOutOfStock(state, val) {
    state.locationsOutOfStock = val;
  },

  setSelectedBopisLocation(state, val) {
    state.selectedBopisLocation = val;
  },

  setInitializingBopis(state, val) {
    state.initializingBopis = Boolean(val);
  },

  setPickItUpSubsId(state, val) {
    state.pickItUpSubsId = val;
  },

  setShowBopis(state, val) {
    state.showBopis = Boolean(val);
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};