export const TOP_NAV = {
  mobileComponent: 'SiteNavMobileMainMenu',
  //- id will not only used as key in v-for but also to track selected
  navItems: [
    {
      title: 'Shop All',
      icon: 'shop',
      url: '/shop-all',
      testExperiment: { name: 'Conversion Pod Launch' },
      desktop: {
        showAsTextCta: true,
      },
    },
    {
      title: 'Shop',
      icon: 'shop',
      testExperiment: { name: 'Conversion Pod Launch', version: 'B', exclude: true },
      subNav: 'SHOP_NAV'
    },
    {
      title: 'Shop By Category',
      icon: 'tube-and-comb',
      testExperiment: { name: 'Conversion Pod Launch' },
      subNav: 'SHOP_NAV'
    },
    {
      title: 'Book Services',
      icon: 'chair',
      subNav: 'SERVICE_NAV'
    },
    {
      title: 'Membership',
      icon: 'ribbon-crown',
      subNav: 'MEMBERSHIP_NAV'
    },
    {
      title: 'Help + How To',
      icon: 'light-bulb',
      subNav: 'HELP_NAV'
    },
    {
      title: 'About',
      icon: 'trust',
      subNav: 'ABOUT_NAV'
    },
    {
      title: 'Blog',
      url: '/blog',
      icon: 'stars'
    }
  ].map((obj, index) => ({ ...obj, id: index + 1 }))
};

export const TOP_NAV_FOR_RC = {
  mobileComponent: 'SiteNavMobileMainMenu',
  //- id will not only used as key in v-for but also to track selected
  navItems: [
    {
      title: 'Shop All',
      icon: 'shop',
      url: '/shop-all',
      testExperiment: { name: 'Conversion Pod Launch' },
      desktop: {
        showAsTextCta: true,
      },
    },
    {
      title: 'Shop',
      icon: 'shop',
      testExperiment: { name: 'Conversion Pod Launch', version: 'B', exclude: true },
      subNav: 'SHOP_NAV'
    },
    {
      title: 'Shop By Category',
      icon: 'tube-and-comb',
      testExperiment: { name: 'Conversion Pod Launch' },
      subNav: 'SHOP_NAV'
    },
    {
      title: 'Book Services',
      icon: 'chair',
      subNav: 'SERVICE_NAV'
    },
    {
      title: 'Membership',
      url: '/dashboard/membership',
      icon: 'star-octagon'
    },
    {
      title: 'How To',
      icon: 'light-bulb',
      url: '/dashboard/how-to',
    },
    {
      title: 'Blog',
      url: '/blog',
      icon: 'stars'
    }
  ].map((obj, index) => ({ ...obj, id: index + 1 }))
};

export const SHOP_NAV = {
  title: 'Shop',
  mobileComponent: 'SiteNavMobileShopMenu',
  navItems: [
    //- Each of these correspond to a mapped menu object bellow
    {
      subNav: 'HAIR_COLOR_NAV'
    },
    {
      subNav: 'GRAY_FIX_NAV'
    },
    {
      subNav: 'COLOR_CARE_NAV'
    },
    {
      subNav: 'STYLE_NAV'
    },
    {
      subNav: 'ACCESSORY_NAV'
    },
    {
      subNav: 'MENS_NAV'
    },
    {
      subNav: 'BUNDLES_AND_SETS'
    },
    {
      subNav: 'SHOP_ALL_PRODUCTS'
    }
  ]
};

export const HAIR_COLOR_NAV = {
  title: 'Hair Color + Highlights',
  mobileComponent: 'SiteNavMobileSubMenu',
  subCategories: [
    {
      title: 'Hair Color',
      navItems: [
        {
          title: 'Find Your Perfect Shade',
          subTitle: 'Take our color quiz',
          url: '/color-advisor?next=/shop/hair-color?include=perfectpair'
        },
        {
          title: 'Permanent Hair Color',
          subTitle: 'Multi-dimensional, long-lasting color provides 100% gray coverage',
          url: '/shop/hair-color?rectype=color',
          testExperiment: { name: 'Colorwonder 2024', version: 'B' },
        },
        {
          title: 'Permanent Hair Color',
          subTitle: 'Lasting, multi-dimensional, high-quality color for all hair types',
          url: '/shop/hair-color?rectype=color',
          testExperiment: { name: 'Colorwonder 2024', version: 'B', exclude: true },
        },
        {
          title: 'Demi Permanent Hair Color',
          subTitle: 'Washes out gradually, formulated for all hair types and textures',
          url: '/product/demi-hair-color-dark-burgundy-red',
          testExperiment: { name: 'Colorwonder 2024', version: 'B' },
        },
        {
          title: 'Compare Shades',
          subTitle: 'Compare similar shades side-by-side',
          url: '/compare-shades',
          testExperiment: { name: 'Colorwonder 2024', version: 'B', exclude: true },
        },
        {
          title: 'Color Reviving Gloss',
          subTitle: 'Refresh faded color on lengths and ends',
          url: '/shopping/gloss'
        }
      ]
    },
    {
      title: 'Highlights',
      navItems: [
        {
          title: 'Light Works® Balayage Highlights Kit',
          subTitle: 'Add natural-looking, sun-kissed highlights',
          url: '/shopping/light-works-highlights',
          ariaLabel: 'Light Works Highlight Kit'
        },
        {
          title: 'Toning Glaze',
          subTitle: 'Refresh and condition faded highlights',
          url: '/shopping/highlights-hair-toner'
        }
      ]
    }
  ]
};

export const GRAY_FIX_NAV = {
  title: 'Quick Fixes For Grays',
  mobileComponent: 'SiteNavMobileSubMenu',
  navItems: [
    {
      title: 'Root Touch Up + Brow Filler',
      subTitle: 'Brush-on powder for instant gray coverage',
      url: '/shop/root-touch-up',
      ariaLabel: 'Root Touch Up Powder'
    },
    {
      title: 'Root Touch Up Kit',
      subTitle: '100% gray coverage in just 10 minutes',
      url: '/shop/root-perfection',
      ariaLabel: 'Root Touch Up Kit'
    }
  ]
};

export const COLOR_SOLVE_SHAMPOO_CONDITIONER_NAV = {
  title: 'shampoo + conditioner',
  navItems: [
    {
      title: 'ColorSolve Toning Shampoo + Conditioner',
      subTitle: 'Four formulas, three shades—customize to the needs + color of your hair',
      url: '/product/brunette-daily-moisture-shampoo-conditioner'
    },
    {
      title: 'ColorSolve Shampoo + Conditioner',
      subTitle: 'Choose from four formulas',
      url: '/product/daily-moisture-shampoo-conditioner',
      ariaLabel: 'ColorSolve Shampoo and Conditioner'
    },
  ]
};

export const COLOR_CARE_NAV = {
  title: 'Color Care + Hair Treatments',
  mobileComponent: 'SiteNavMobileSubMenu',
  subCategories: [
    {
      title: 'Hair treatments',
      navItems: [
        {
          title: 'Pro Boost Treatments',
          subTitle: 'Hydrate, strengthen, lock in color, or add shine',
          url: '/shop/pro-boost'
        },
        {
          title: 'Color Therapy Hair Mask',
          subTitle: 'Deep condition and refresh color in 5 minutes',
          url: '/shopping/color-therapy'
        },
        {
          title: 'Prime For Perfection',
          subTitle: 'Prime your hair for a fresh start before coloring',
          url: '/shopping/prime-for-perfection',
          ariaLabel: 'Prime For Perfection'
        },
        {
          title: 'Bond Building Treatment',
          subTitle: 'Strengthen, repair and reduce hair breakage',
          url: '/product/bonding-time',
        }
      ]
    },
    COLOR_SOLVE_SHAMPOO_CONDITIONER_NAV
  ]
};

export const STYLE_NAV = {
  title: 'Styling Products',
  mobileComponent: 'SiteNavMobileSubMenu',
  navItems: [
    {
      title: 'Loud + Proud Volumizing Foam',
      subTitle: 'Add volume and instant body',
      url: '/product/loud-proud'
    },
    {
      title: 'Sleek Attack Straightening Cream',
      subTitle: 'Smooth and condition',
      url: '/product/sleek-attack'
    },
    {
      title: 'Let’s Bounce Curl-Defining Cream',
      subTitle: 'Define and nourish curls',
      url: '/product/lets-bounce'
    },
    {
      title: 'Tame Frizz-Control Cream',
      subTitle: 'Control frizz and boost shine',
      url: '/product/tame'
    }
  ]
};

export const ACCESSORY_NAV = {
  title: 'Tools + Accessories',
  mobileComponent: 'SiteNavMobileSubMenu',
  subCategories: [
    {
      title: 'Color application tools',
      navItems: [
        {
          title: 'Professional Color Tool Kit',
          subTitle: '4 piece set to help you color like a pro',
          url: '/product/coloring-tool-kit'
        },
        {
          title: 'Salon Smock',
          url: '/product/smock'
        },
        {
          title: 'Shop All Tools',
          url: '/shop/accessories',
          ariaLabel: 'Accessories'
        }
      ]
    },
    {
      title: 'Styling tools',
      navItems: [
        {
          title: 'Detangling Brush',
          url: '/product/detangling-brush'
        }
      ],
      testExperiment: { name: 'Colorwonder 2024', version: 'B', exclude: true },
    }
  ]
};

export const MENS_NAV = {
  title: "Men's Hair Color",
  mobileComponent: 'SiteNavMobileSubMenu',
  navItems: [
    {
      title: "Shop Men's Hair Color",
      url: '/men',
      ariaLabel: `Men's Hair Color`
    }
  ]
};

export const BUNDLES_AND_SETS = {
  title: "Bundles + Sets",
  mobileComponent: 'SiteNavMobileSubMenu',
  navItems: [
    {
      title: 'The Perfect Pair',
      subTitle: 'Salon-secret system: Permanent Color + Gloss',
      url: '/bundle/perfect-pair',
      testExperiment: { name: 'Perfect Pair PDP' },
    },
    {
      title: 'Shop Bundles + Sets',
      url: '/shop/bundles'
    },
  ]
};

export const SHOP_ALL_PRODUCTS = {
  navItems: [
    {
      title: 'Shop All Products',
      url: '/shop-all',
      standaloneUrl: true
    }
  ]
};

export const SERVICE_NAV = {
  title: 'Book Services',
  mobileComponent: 'SiteNavMobileSubMenu',
  navItems: [
    {
      title: 'Book Now',
      subTitle: 'Find a Hair Color Bar Near You',
      ariaLabel: 'Hair Color Bar Locations',
      url: '/colorbar/locations'
    },
    {
      title: 'Hair Color Services',
      subTitle: 'Explore our Hair Color Services',
      ariaLabel: 'Hair Color Services',
      url: '/haircolorbar/services'
    },
    {
      title: 'What is a Hair Color Bar?',
      subTitle: 'Learn more about our services and retail locations',
      url: '/colorbar',
      ariaLabel: 'Hair Color Bar'
    },
    {
      title: 'Hair Color Bar Memberships',
      subTitle: 'Get discounts on roots services and more',
      url: '/haircolorbar/memberships'
    }
  ]
};

export const MEMBERSHIP_NAV = {
  title: 'Membership',
  mobileComponent: 'SiteNavMobileSubMenu',
  navItems: [
    {
      title: 'At-Home Memberships',
      subTitle: 'Get discounts on at-home hair color and more',
      url: '/home-memberships'
    },
    {
      title: 'Hair Color Bar Memberships',
      subTitle: 'Get discounts on roots services and more',
      url: '/haircolorbar/memberships'
    }
  ]
};

export const HELP_NAV = {
  title: 'Help + how to',
  mobileComponent: 'SiteNavMobileSubMenu',
  subCategories: [
    {
      title: 'Hair color Help',
      navItems: [
        {
          title: 'Free Video Consult',
          url: '/schedule-video-chat',
          ariaLabel: 'Video Consultation'
        },
        {
          title: 'FAQs',
          url: 'https://support.madison-reed.com/hc/en-us'
        },
        {
          title: 'Color System',
          subTitle: 'Create your Madison Reed hair color routine',
          url: '/shopping/system'
        }
      ]
    },
    {
      title: 'How To',
      navItems: [
        {
          title: 'Hair Color House Parties',
          subTitle: 'Live coloring demos + Q&A',
          url: 'http://more.madison-reed.com/hair-color-house-party'
        },
        {
          title: 'How to: Permanent Hair Color',
          url: '/how-to-color-your-hair',
          ariaLabel: 'Tutorials and Videos'
        },
        {
          title: 'How to: Balayage Highlights',
          url: '/shopping/light-works-highlights'
        }
      ]
    }
  ]
};

export const ABOUT_NAV = {
  title: 'About',
  mobileComponent: 'SiteNavMobileSubMenu',
  subCategories: [
    {
      title: 'About Madison Reed',
      navItems: [
        {
          title: 'Our Story',
          url: '/learn-more',
          ariaLabel: 'Learn More'
        },
        {
          title: 'Store Locator',
          url: '/store-locator',
          ariaLabel: 'Store Locator'
        },
        {
          title: 'Ingredients with Integrity',
          url: '/hair-dye-ingredients',
          ariaLabel: 'Hair Dye Ingredients'
        },
        {
          title: 'Backed by Expert Colorists',
          url: '/hair-colorist',
          ariaLabel: 'Professional Colorists'
        }
      ]
    },
    {
      title: 'Careers',
      navItems: [
        {
          title: 'Join Our HQ team',
          subTitle: 'We’re hiring!',
          url: '/about/careers',
          ariaLabel: 'Careers'
        },
        {
          title: 'Join Our Hair Color Bar Team',
          subTitle: 'We’re hiring colorists & more!',
          url: 'https://haircolorbarcareers.madison-reed.com/'
        }
      ]
    }
  ]
};

export default {
  TOP_NAV,
  SHOP_NAV,
  HAIR_COLOR_NAV,
  GRAY_FIX_NAV,
  COLOR_CARE_NAV,
  COLOR_SOLVE_SHAMPOO_CONDITIONER_NAV,
  STYLE_NAV,
  ACCESSORY_NAV,
  MENS_NAV,
  BUNDLES_AND_SETS,
  SHOP_ALL_PRODUCTS,
  SERVICE_NAV,
  MEMBERSHIP_NAV,
  HELP_NAV,
  ABOUT_NAV
};