import mrApi from './mrApi';

export default {
  getLoggedInInfo,
  requiresLogin,
  loadCustomer,
  updateCustomer,
  updatePassword,
  uploadCustomerImage,
  uploadCustomerImages,
  smsOptInOut,
  setMarketingPreferences,
  getMarketingPreferences,
  getPaginatedPurchaseHistory,
  getOrderSummary,
  getOrderDataForColorSolution,
  getMostRecentColorOrder,
  getCustomerRecentInvoices,
  loadLiveInvoicesByPaymentProfileId,
  getReviewableProductsForCustomer,
  getCustomerCdata,
  getSignInData,
  verifyCipherStr,
  verifyMagicLinkCipherStr,
  resetPassword,
  sendMagicLink,
  loginWithMagicLink,
  verifyEmailHasAssociatedPassword,
  sendPasswordResetSMS,
  facebookLogin,
  googleLogin,
  getGoogleLoginParams,
  getAccountStatus,
  login,
  cancelOrder,
  assignCustomerPromo,
  assignCustomerPromos,
  getCustomInstructions,
  createCustomer,
  createCustomerWithMagicLinkVerification,
  createCustomerFromEmailCapture,
  addFirstTimeVisitorPhoneAndOffer,
  sendFirstTimeVisitorPhoneOffer,
  getAllCustomerPromos,
  newsletterSignUp,
  getAppointmentsData,
  getCustomerUnlimitedRootsMembershipPerks,
  getCustomerLimitlessProPlusPerks,
  getZendeskTicketBySubject,
  verifyOneTimePassword,
  resendOneTimePassword,
  sendPatchAckSignedEmail,
  sendZendeskForPatchTestSensitivity,
  customerHasActiveLimitlessPlus,
  getMembershipWithLoyaltyData,
};

export function getLoggedInInfo() {
  return mrApi.post('/api/customer/getLoggedInInfo');
}

export function requiresLogin() {
  return mrApi.post('/api/customer/requiresLogin');
}

export function loadCustomer() {
  return mrApi.get('/api/customer/getCustomerInfo', { params: {} });
}

export function updateCustomer(customerInfo) {
  return mrApi.post('/api/account/updateAccount', customerInfo);
}

export function updatePassword(passwordData) {
  return mrApi.post('/api/customer/updatePassword', passwordData);
}

export function uploadCustomerImage(formData) {
  return mrApi.post('/api/customer/uploadCustomerImage', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export function uploadCustomerImages(images, context) {
  var formDataArray = images.map(image => {
    var formData = new FormData();

    formData.append('file', image);

    if (context) {
      formData.append('imageContext', context);
    }

    return formData;
  });
  return Promise.all(formDataArray.map(uploadCustomerImage));
}

export function smsOptInOut(params) {
  return mrApi.post('/api/customer/smsOptInOut', params);
}

export function setMarketingPreferences(params) {
  return mrApi.post('/api/customer/setMarketingPreferences', params);
}

export function getMarketingPreferences() {
  return mrApi.get('/api/customer/getMarketingPreferencesByUserId');
}

export function getCustomerRecentInvoices() {
  return mrApi.get('/api/customer/getCustomerRecentInvoices');
}

export function loadLiveInvoicesByPaymentProfileId(params) {
  return mrApi.get('/api/customer/loadLiveInvoicesByPaymentProfileId', { params });
}

export function getReviewableProductsForCustomer() {
  return mrApi.get('/api/customer/getReviewableProductsForCustomer');
}

export function getPaginatedPurchaseHistory(params) {
  return mrApi.get('/api/customer/getPaginatedPurchaseHistory', { params });
}

export function getOrderSummary(orderId) {
  return mrApi.get('/api/account/getOrderSummary', {
    params: { orderId }
  });
}

export function getOrderDataForColorSolution(params) {
  return mrApi.get('/api/account/getOrderDataForColorSolution', { params });
}

export function getMostRecentColorOrder() {
  return mrApi.get('/api/customer/getMostRecentColorOrder');
}

export function getCustomerCdata() {
  return mrApi.get('/api/customer/getCustomerCdata');
}

export function getSignInData() {
  return mrApi.get('/api/customer/getSignInData');
}

export function verifyCipherStr(params) {
  return mrApi.get('/api/customer/verifyCipherStr', { params });
}

export function verifyMagicLinkCipherStr(params) {
  return mrApi.get('/api/customer/verifyMagicLinkCipherStr', { params });
}

export function resetPassword(params) {
  return mrApi.post('/api/customer/resetPassword', params);
}

export function sendMagicLink(params) {
  return mrApi.post('/api/customer/sendMagicLink', params);
}

export function loginWithMagicLink(params) {
  return mrApi.post('/api/customer/loginWithMagicLink', params);
}

export function forgotPassword(params) {
  return mrApi.post('/api/customer/forgotPassword', params);
}

export function verifyEmailHasAssociatedPassword(params) {
  return mrApi.post('/api/customer/verifyEmailHasAssociatedPassword', params);
}

export function sendPasswordResetSMS(params) {
  return mrApi.post('/api/customer/sendPasswordResetSMS', params);
}

export function facebookLogin(params) {
  return mrApi.post('/api/customer/facebookLogin', params);
}

export function googleLogin(params) {
  return mrApi.post('/api/customer/googleLogin', params);
}

export function getGoogleLoginParams() {
  return mrApi.post('/api/customer/getGoogleLoginParams');
}

export function encodeFacebookUserId(params) {
  return mrApi.post('/api/customer/encodeFacebookUserId', params);
}

export function getAccountStatus(params) {
  return mrApi.post('/api/customer/getAccountStatus', params);
}

export function login(params) {
  return mrApi.post('/api/customer/login', params);
}

export function logout() {
  return mrApi.post('/api/customer/logout');
}

export function clearSession() {
  return mrApi.post('/api/customer/clearSession');
}

export function createCustomer(params) {
  return mrApi.post('/api/customer/createCustomer', params);
}

export function createCustomerWithMagicLinkVerification(params) {
  return mrApi.post('/api/customer/createCustomerWithMagicLinkVerification', params);
}

export function assignCustomerPromo(params) {
  return mrApi.post('/api/customer/assignCustomerPromo', params);
}

export function assignCustomerPromos(params) {
  return mrApi.post('/api/customer/assignCustomerPromos', params);
}

export function getCustomInstructions() {
  return mrApi.get('/api/customer/getAllCustomInstructionsForCustomer');
}

export function createCustomerFromEmailCapture(formData) {
  return mrApi.post('/api/customer/createCustomerFromEmailCapture', formData);
}

export function addFirstTimeVisitorPhoneAndOffer(params) {
  return mrApi.post('/api/customer/addFirstTimeVisitorPhoneAndOffer', params);
}

export function sendFirstTimeVisitorPhoneOffer(params) {
  return mrApi.post('/api/customer/sendFirstTimeVisitorPhoneOffer', params);
}

export function cancelOrder(params) {
  return mrApi.post('/api/customer/cancelOrder', params);
}
/**
 * getAllCustomerPromos
 *
 * @param {object} params
 * @returns {Promise} promise
 */
export function getAllCustomerPromos(params) {
  return mrApi.get('/api/customer/getAllCustomerPromos', { params });
}

export function newsletterSignUp(params) {
  return mrApi.post('/api/customer/newsletterSignUp', params);
}

export function getAppointmentsData(params) {
  return mrApi.get('/api/customer/getAppointmentsData', params);
}

export function getCustomerUnlimitedRootsMembershipPerks(params) {
  return mrApi.get('/api/customer/getUnlimitedMembershipPromos', { params });
}

export function getCustomerLimitlessProPlusPerks(params) {
  return mrApi.get('/api/customer/getLimitlessProPlusPerks', { params });
}

export function getZendeskTicketBySubject(params) {
  return mrApi.get('/api/customer/getZendeskTicketBySubject', { params });
}

export function verifyOneTimePassword(params) {
  return mrApi.post('/api/customer/verifyOneTimePassword', params);
}

export function resendOneTimePassword(params) {
  return mrApi.post('/api/customer/resendOneTimePassword', params);
}

export function sendPatchAckSignedEmail(params) {
  return mrApi.post('/api/customer/sendPatchAckSignedEmail', params);
}

export function sendZendeskForPatchTestSensitivity(params) {
  return mrApi.post('/api/customer/sendZendeskForPatchTestSensitivity', params);
}

export function customerHasActiveLimitlessPlus() {
  return mrApi.get('/api/customer/customerHasActiveLimitlessPlus');
}

export function getMembershipWithLoyaltyData() {
  return mrApi.get('/api/customer/getMembershipWithLoyaltyData');
}

export function getCustomerMembershipDetails() {
  return mrApi.get('/api/customer/getCustomerMembershipDetails');
}