/* global MR, braintree */
import vuePaymentSvc from "../../services/vuePaymentSvc";
import { isSameAddress } from "../../utilities/mrVueUtils";
import { updatePaymentProfileForSubscriptions } from "../../services/vueSubscriptionSvc";
import { mrCardValidator, mrCardExpirationValidator } from '../../utilities/customValidators';
import { getObjProperty } from "@utilities/mrVueUtils";

const { required, minLength } = require("vuelidate/lib/validators");

async function doSaveProfileForm({ commit, state, getters, dispatch, rootGetters, rootState }) {
  let oldPaymentProfileId = state.editProfileForm.oldPaymentProfileId;
  //- update all subs when customer intends to do that
  if (state.editProfileForm.use_payment_for_all_subscriptions) {
    commit('updateProfileForSubs', rootGetters['subscriptions/activeSubscriptionIds']);
  }

  var payload = {
    paymentProfileId: state.editProfileForm.paymentProfileId,
    updateDeclinedCC: state.editProfileForm.isDeclined,
    is_default: state.editProfileForm.is_default,
    updateAllSubs: state.editProfileForm.updateAllSubs,
    oldPaymentProfileId
  };

  //- when user is 'editing' a payment profile check if the old profile was attached to any subs,
  //- if so pass the sub ids in the payload so we can attache the 'new' payment to the same subs
  //- ('editing' payment means add new and soft delete the old)
  if (oldPaymentProfileId) {
    let oldProfile = getters.getPaymentProfileById(oldPaymentProfileId);
    let subIds = (oldProfile.subs || []).map(sub => sub.id);
    payload.update_profile_for_subs = subIds;
  }

  var address = rootState.addressBook.editAddressForm;
  if (!address) {
    address = {};
  }

  if (state.editProfileForm.same_as_shipping) {
    let defaultShipAddress = rootGetters['addressBook/defaultShipAddress'];

    if (!defaultShipAddress.id) {
      dispatch('notifyError', 'Please complete your shipping information.', { root: true });
      commit('setSavingProfile', false);
      throw Error();
    }

    address = Object.assign({}, defaultShipAddress);
  }

  var firstName = address.first_name;
  var lastName = address.last_name;

  var card = {
    cardholderName: [firstName, lastName].filter(Boolean).join(' '),
    expirationMonth: state.editProfileForm.cc_exp_month,
    expirationYear: state.editProfileForm.cc_exp_year,
    billingAddress: {
      firstName: firstName,
      lastName: lastName,
      streetAddress: address.street_1 || '',
      extendedAddress: address.street_2 || '',
      locality: address.city || '',
      region: address.region || '',
      postalCode: address.post_code || ''
    }
  };

  payload.address = address;

  if (state.editProfileForm.cc_number) {
    card.number = state.editProfileForm.cc_number;
  }

  if (state.editProfileForm.cc_cvc) {
    card.cvv = state.editProfileForm.cc_cvc;
  }

  try {
    const tokenResponse = await vuePaymentSvc.getClientToken();
    const client = await braintree.client.create({
      authorization: tokenResponse.data.clientToken
    });
    const cardResponse = await client.request({
      endpoint: 'payment_methods/credit_cards',
      method: 'post',
      data: {
        creditCard: card,
      }
    });
    const dataCollector = await braintree.dataCollector.create({
      kount: { environment: MR.config.braintreeEnvironment },
      client
    });
    payload.nonce = cardResponse.creditCards[0].nonce;
    payload.deviceData = dataCollector.deviceData;
    payload.captchaResponse = state.paymentCaptchaResponse;

  } catch (e) {
    dispatch('notifyError', null, { root: true });
    commit('setSavingProfile', false);
    throw e;
  }

  try {
    const savePaymentRes = await vuePaymentSvc.savePaymentProfile(payload);
    commit('setSavingProfile', false);
    dispatch('notifySuccess', 'Thanks! Your billing information has been updated.', { root: true });
    //- because we allow user to save a new address while delete
    //- an address in one swoop lets check if there is one set for deletion
    if (state.profileToDelete.paymentProfileId) {
      dispatch('deleteProfile');
      return;
    }

    if (oldPaymentProfileId) {
      //- keep it for the record in the state, will have to ignore a watcher for
      //- subscription update
      savePaymentRes.data.oldPaymentProfileId = oldPaymentProfileId;
    }

    savePaymentRes.data.is_default = payload.is_default;

    commit('setSelectedProfileId', savePaymentRes.data.paymentProfileId);
    dispatch('updateLastSavedProfile', savePaymentRes.data);
    dispatch('clearEditProfileForm');

    await Promise.all([
      dispatch('getCustomerPaymentProfiles'),
      dispatch('subscriptions/loadCustomerSubs', null, { root: true })
    ]);
    dispatch('updateSubsModal');

  } catch (err) {
    let errMsg = null;
    let errRes = err.response;
    if ((errRes + '').toLowerCase().indexOf('credit card declined') >= 0) {
      errMsg = 'Your card has been declined. Please verify your information and resubmit your order. Thank you!';
    } else if (getObjProperty(errRes, 'data.message')) {
      errMsg = errRes.data.message;
    } else {
      errMsg = null;
    }

    dispatch('notifyError', errMsg, { root: true });
    commit('setSavingProfile', false);
    dispatch('getCustomerPaymentProfiles');
    throw err;
  }

}

async function getBraintreePayload(card, token) {
  try {
    const client = await braintree.client.create({
      authorization: token.clientToken
    });
    const dataCollector = await braintree.dataCollector.create({
      kount: { environment: MR.config.braintreeEnvironment },
      client
    });
    const cardResponse = await client.request({
      endpoint: 'payment_methods/credit_cards',
      method: 'post',
      data: {
        creditCard: card,
      }
    });
    return {
      nonce: cardResponse.creditCards[0].nonce,
      deviceData: dataCollector.deviceData,
      address: {}
    };
  } catch (e) {
    throw Error(e);
  }
}

function PaymentProfile() {
  this.cc_cvc = '';
  this.cc_exp_month = '';
  this.cc_exp_year = '';
  this.cc_number = '';
  this.is_default = true;
  this.same_as_shipping = true;
  this.use_payment_for_all_subscriptions = true;
  this.ccType = '',
  this.ccCvLength = '',
  this.ccSize = '',
  this.cvvRegex = '',
  this.ccLength = '',
  this.ccMask = '',
  this.cvcMask = '',
  this.address = {
    first_name: '',
    last_name: '',
    street_1: '',
    street_2: '',
    city: '',
    post_code: '',
    region: '',
    phone: '',
    isBillingAddress: true
  };
}

const ccTypes = {
  visa: {
    regex: /^4/,
    cvc: 3,
    cvcMask: '###',
    size: 16,
    formSize: 19,
    mask: '#### #### #### ####'
  },
  mc: {
    regex: /^(5[1-5]|2[2-7])/,
    cvc: 3,
    cvcMask: '###',
    size: 16,
    formSize: 19,
    mask: '#### #### #### ####'
  },
  amex: {
    regex: /^3[47]/,
    cvc: 4,
    cvcMask: '####',
    size: 15,
    formSize: 18,
    mask: '#### ###### #####'
  },
  discover: {
    regex: /^6(?:011|5)/,
    cvc: 3,
    cvcMask: '###',
    size: 16,
    formSize: 19,
    mask: '#### #### #### ####'
  },
  default: {
    regex: /^[0-9]/,
    cvc: 3,
    cvcMask: '###',
    size: 16,
    formSize: 19,
    mask: '#### #### #### ####'
  }
};

export const state = {
  profiles: [],
  addedProfiles: [], // for video chat and non loged in users
  selectedProfileId: null,
  videoChatPaymentProfileForm: false,
  editProfileForm: {},
  savingProfile: false,
  profileFormIsValid: false,
  lastSavedProfile: {},
  loadingPayments: false,
  profileToDelete: {},
  profileFormValidators: {
    cc_number: {
      required,
      mrCardValidator: {}
    },

    cc_exp_month: {
      required,
      mrCardExpirationValidator,
    },

    cc_exp_year: {
      required,
    },

    cc_cvc: {
      required,
      minLength: minLength(3)
    },

    ccExpDate: ['editProfileForm.cc_exp_month', 'editProfileForm.cc_exp_year'],

  },
  paymentCaptchaResponse: null,
  validAmazonSession: false,
  afterPayBillingAgreementToken: null,
  afterPayCheckoutToken: null,
  amazonBillingAgreementId: null,
};

export const getters = {
  isApplePaySelectedInCheckout(state, getters) {
    return Boolean(state.selectedProfileId && (getters.getPaymentProfileById(state.selectedProfileId) || {}).paymentGateway === 'apple pay');
  },
  profilesWithoutApplePay(state) {
    return state.profiles.filter(profile => profile.paymentGateway !== 'apple pay');
  },
  afterPayProfileId(state) {
    return ((state.profiles || []).find(({ paymentGateway }) => paymentGateway === 'afterpay') || {}).paymentProfileId;
  },

  getPaymentProfileById: (state) => (paymentProfileId) => {
    return state.profiles.find(profile => profile.paymentProfileId == paymentProfileId) || {};
  },

  defaultPaymentProfile(state) {
    return state.profiles.find(profile => profile.is_default && profile.paymentGateway !== 'apple pay');
  },

  expiringSoon(state) {
    return state.profiles.find(profile => profile.expiresIn > 0 && profile.expiresIn < 30);
  },

  hasPaymentProfile(state) {
    return state.profiles && state.profiles.length > 0;
  },

  validPaymentProfiles(state) {
    return state.profiles.filter(p =>  !(p.isDeclined || p.expiresIn < 0));
  },

  selectedPaymentProfile(state) {
    return state.profiles.find(profile => profile.paymentProfileId == state.selectedProfileId) || {};
  },

  selectedPaymentProfileIsValid(state, getters) {
    return Boolean(!getters.selectedPaymentProfile.isDeclined && getters.selectedPaymentProfile.expiresIn > 0);
  },
};

export const actions = {
  testCardNum({ state, dispatch }, optionalNumber) {
    if (state.editProfileForm.paymentProfileId) {
      return;
    }

    var key;
    var cType;

    for (key in ccTypes) {
      if (ccTypes[key].regex.test(state.editProfileForm.cc_number || optionalNumber)) {
        cType = key;
        dispatch('setCcFormSettings', cType);
        return;
      }
    }

    dispatch('setCcFormSettings', 'default');
  },

  setCcFormSettings({ commit, state }, cType) {
    if (state.editProfileForm.ccType == cType) {
      return;
    }

    commit('setCcType', cType);
    commit('setCcCvLength', ccTypes[cType].cvc);
    commit('setCcLength', ccTypes[cType].formSize);
    commit('setCcMask', ccTypes[cType].mask);
    commit('setCvcMask', ccTypes[cType].cvcMask);
    commit('setCcSize', ccTypes[cType].size);
    commit('setCcCvLengthValidation', minLength(state.editProfileForm.ccCvLength));
    commit('setCcNumberValidator', mrCardValidator(state.editProfileForm.ccSize));
  },

  //- use this when need to ensure that payments are loaded
  initPaymentProfiles({ state, dispatch }) {
    if (state.profiles.length) {
      return;
    }
    dispatch('getCustomerPaymentProfiles');
  },
  //- payment refresh/load
  getCustomerPaymentProfiles({ commit, dispatch, state, getters }, { manualPaymentProfileId, setDefaultToSelected } = {}) {
    commit('setLoadingPayments', true);
    return vuePaymentSvc.getPaymentProfiles().then(res => {
      commit('setPaymentProfiles', res.data);
      //- ensure default payment profile
      if (state.profiles.length > 0 && !getters.defaultPaymentProfile) {
        if (getters.profilesWithoutApplePay.length > 0) {
          dispatch('updateDefaultPaymentProfile', { paymentProfileId: getters.profilesWithoutApplePay[0].paymentProfileId });
        }
      }

      if (setDefaultToSelected && getters.defaultPaymentProfile) {
        commit('setSelectedProfileId', getters.defaultPaymentProfile.paymentProfileId);
      }

      if (manualPaymentProfileId) {
        commit('setSelectedProfileId', manualPaymentProfileId);
      }
    }).catch(() => {
      dispatch('notifyError', null, { root: true });
    }).finally(() => {
      commit('setLoadingPayments', false);
      //- check if selectedProfileId was set and that it still exists, if not set one instead
      const selectedExists = state.profiles.some(profile => profile.paymentProfileId == state.selectedProfileId);
      if (state.selectedProfileId && !selectedExists && getters.defaultPaymentProfile) {
        commit('setSelectedProfileId', getters.defaultPaymentProfile.paymentProfileId);
      }
    });
  },

  populateEditProfileForm({ commit, dispatch, rootGetters, state }, paymentProfile = {}) {
    //- we pass in paymentProfile when user "edits" their payment method so that we can keep track of the
    //- old payment profile id. in the case of "edit" we will update all subscriptions with the new payment
    //- after save and soft delete the old one using "oldPaymentProfileId"
    let { paymentProfileId, address } = paymentProfile;
    let newPaymentProfile = new PaymentProfile();

    if (paymentProfileId) {
      newPaymentProfile.oldPaymentProfileId = paymentProfileId;
    }

    let defaultShipAddress = rootGetters['addressBook/defaultShipAddress'];
    if (address) {
      newPaymentProfile.address = address;
      newPaymentProfile.address.isBillingAddress = true;
    }

    if (address && defaultShipAddress && paymentProfileId) {
      //- compare payment address to default ship address and set same_as_shipping accordingly
      newPaymentProfile.same_as_shipping = isSameAddress(address, defaultShipAddress);

      newPaymentProfile.use_payment_for_all_subscriptions = false;
    }

    if (!defaultShipAddress) {
      newPaymentProfile.same_as_shipping = false;
    }

    // we don't want to override the default shipping method nor the shipping address
    if (state.videoChatPaymentProfileForm) {
      newPaymentProfile.same_as_shipping = false;
      newPaymentProfile.is_default = false;
    }

    dispatch('addressBook/populateEditAddressForm', newPaymentProfile.address, { root: true });
    commit('setEditProfileForm', newPaymentProfile);
    dispatch('testCardNum');
  },

  validateProfileForm({ commit }) {
    //- by setting it to true it will trigger watch on
    //- addressFormInputs and call $touch()
    //- always start with invalid profile
    commit('setProfileFormIsValid', false);
    commit('setValidateProfileForm', true);
  },

  //- Saves a new payment profile without a billing address
  async simpleSaveProfile({ commit, state, dispatch }, card = {}) {
    if (state.savingProfile) {
      return;
    }

    commit('setSavingProfile', true);

    try {
      const { data: token } = await vuePaymentSvc.getClientToken();
      const payload = await getBraintreePayload(card, token);
      payload.captchaResponse = state.paymentCaptchaResponse;
      const { data: newPaymentProfileData } = await vuePaymentSvc.savePaymentProfile(payload);
      commit('setSavingProfile', false);
      return newPaymentProfileData;
    } catch (error) {
      dispatch('notifyError', error, { root: true });
      commit('setSavingProfile', false);
      throw error;
    }
  },

  async addVideoChatPaymentMethod({ commit, state, dispatch, rootState }) {
    if (state.savingProfile) {
      return;
    }

    commit('setSavingProfile', true);

    const address = rootState.addressBook.editAddressForm || {};
    const videoChatCustomer = rootState.videoChat.customer || {};
    const room = rootState.videoChat.room;

    const firstName = address.first_name;
    const lastName = address.last_name;

    const card = {
      cardholderName: [firstName, lastName].filter(Boolean).join(' '),
      expirationMonth: state.editProfileForm.cc_exp_month,
      expirationYear: state.editProfileForm.cc_exp_year,
      billingAddress: {
        firstName: firstName,
        lastName: lastName,
        streetAddress: address.street_1 || '',
        extendedAddress: address.street_2 || '',
        locality: address.city || '',
        region: address.region || '',
        postalCode: address.post_code || ''
      }
    };

    if (state.editProfileForm.cc_number) {
      card.number = state.editProfileForm.cc_number;
    }

    if (state.editProfileForm.cc_cvc) {
      card.cvv = state.editProfileForm.cc_cvc;
    }

    try {
      const { data: token } = await vuePaymentSvc.getClientToken();
      const payload = await getBraintreePayload(card, token);
      payload.customerId = videoChatCustomer.id;
      payload.room = room;
      payload.captchaResponse = state.paymentCaptchaResponse;
      const { data: newPaymentProfileData } = await vuePaymentSvc.addVideoChatPaymentMethod(payload);
      commit('setSavingProfile', false);
      dispatch('updateLastSavedProfile', newPaymentProfileData);
      commit('addNewAddedProfile', newPaymentProfileData);
      dispatch('clearEditProfileForm');
    } catch (error) {
      dispatch('notifyError', error, { root: true });
      commit('setSavingProfile', false);
      throw error;
    }
  },

  //- Save Payment flow chart https://www.lucidchart.com/invitations/accept/79ee770f-32a1-4281-b913-0b989d8e8a24
  saveProfileForm({ commit, state, getters, dispatch, rootGetters, rootState }) {
    if (state.savingProfile) {
      return;
    }

    commit('setSavingProfile', true);

    return doSaveProfileForm({ commit, state, getters, dispatch, rootGetters, rootState });
  },

  updateDefaultPaymentProfile({ dispatch }, params) {
    return vuePaymentSvc.setDefaultPaymentProfile({ paymentProfileId: params.paymentProfileId })
      .then(() => {
        dispatch('getCustomerPaymentProfiles');
        dispatch('subscriptions/loadCustomerSubs', null, { root: true });
        dispatch('notifySuccess', 'Your default payment method was successfully updated.', { root: true });
      })
      .catch(() => {
        dispatch('notifyError', null, { root: true });
      });
  },

  editPaymentModal({ dispatch }, paymentProfile) {
    dispatch('populateEditProfileForm', paymentProfile);
    dispatch('modal/showModal', {
      component: 'PaymentEditModal',
    }, { root: true });
  },

  updateSubsModal({ state, commit, dispatch, rootGetters }) {
    let activeSubscriptions = rootGetters['subscriptions/activeSubscriptions'];

    if (state.lastSavedProfile.paymentProfileId && state.lastSavedProfile.is_default && activeSubscriptions.length) {
      let subsToUpdate = activeSubscriptions.filter(sub => {
        return sub.payment_id != state.lastSavedProfile.paymentProfileId;
      });

      if (subsToUpdate.length) {
        dispatch('subscriptions/updateSubsToUpdate', subsToUpdate, { root: true });
        dispatch('modal/showModal', {
          component: 'PaymentUpdateSubsModal',
        }, { root: true });
        commit('modal/setPersistent', true, { root: true });
      }
    }
  },

  deletePaymentModal({ commit, dispatch }, profile) {
    commit('setProfileToDelete', profile);
    var modal = 'PaymentDeleteModal';
    if (profile.subs && profile.subs.length) {
      dispatch('populateEditProfileForm', profile);
      modal = 'PaymentDeleteHasSubsModal';
    }

    dispatch('modal/showModal', { component: modal }, { root: true });
  },

  deleteProfileWithSubReplace({ commit, dispatch, state }, paymentProfileId) {
    var subIds = state.profileToDelete.subs.map(sub => sub.id);
    //- if paymentProfileId passed use that for all subs
    if (paymentProfileId) {
      //- update all subs to new paymentProfileId and then delete the profile
      return dispatch('updatePaymentProfileForSubscriptions', { paymentProfileId, subIds })
        .then(() => {
          dispatch('deleteProfile');
        });
    }

    //- else check for new profileForm and save new payment, then use that for subsIds
    //- which gets handled in saveProfile action
    commit('updateProfileForSubs', subIds);
    dispatch('saveProfileForm');
  },

  deleteProfile({ commit, dispatch, state }) {
    let { paymentProfileId } = state.profileToDelete;
    if (!paymentProfileId) {
      return dispatch('notifyError', 'No payment method has been selected for deletion.', { root: true });
    }

    vuePaymentSvc.deletePaymentProfile({ paymentProfileId })
      .then(() => {
        commit('clearProfileToDelete');
        dispatch('getCustomerPaymentProfiles');
        dispatch('notifySuccess', 'Your payment method was successfully removed', { root: true });
      })
      .catch((err) => {
        var message = null;
        if (err.response && err.response.data && err.response.data.message) {
          message = err.response.data.message;
        }
        dispatch('notifyError', message, { root: true });
      });
  },

  updatePaymentProfileForSubscriptions({ dispatch }, params) {
    var payload = {
      subIds: params.subIds,
      paymentProfileId: params.paymentProfileId
    };

    return new Promise((resolve, reject) => {
      updatePaymentProfileForSubscriptions(payload)
        .then(() => {
          dispatch('subscriptions/loadCustomerSubs', null, { root: true });
          dispatch('notifySuccess', 'Payment method for all subscriptions has been updated', { root: true });
          resolve();
        })
        .catch(() => {
          dispatch('notifyError', null, { root: true });
          reject();
        });
    });
  },

  clearEditProfileForm({ commit, dispatch }) {
    commit('clearEditProfileForm');
    dispatch('addressBook/clearEditAddressForm', null, { root: true });
  },

  clearSelectedProfile({ commit }) {
    commit('setSelectedProfileId', null);
  },

  clearVideoChatPaymentProfileForm({ commit }) {
    commit('setVideoChatPaymentProfileForm', false);
  },

  setVideoChatPaymentProfileForm({ commit }, val) {
    commit('setVideoChatPaymentProfileForm', val);
  },

  updateCardValidator({ commit }, ccSize) {
    let validator = mrCardValidator.call(ccSize);
    commit('setCardValidator', validator);
  },

  updateLastSavedProfile({ commit }, profile) {
    commit('setLastSavedProfile', profile);
  }
};

export const mutations = {
  addNewAddedProfile(state, newProfile) {
    state.addedProfiles.push(newProfile);
  },

  setVideoChatPaymentProfileForm(state, val) {
    state.videoChatPaymentProfileForm = !!val;
  },

  setPaymentProfiles(state, val) {
    state.profiles = val;
  },

  setLoadingPayments(state, val) {
    state.loadingPayments = Boolean(val);
  },

  setEditProfileForm(state, val) {
    state.editProfileForm = Object.assign({}, val);
  },

  setProfileToDelete(state, val) {
    state.profileToDelete = val;
  },

  setSavingProfile(state, val) {
    state.savingProfile = val;
  },

  setValidateProfileForm(state, val) {
    state.validateProfileForm = val;
  },

  setProfileFormIsValid(state, val) {
    state.profileFormIsValid = val;
  },

  setCardValidator(state, val) {
    state.profileFormValidators.cc_number.mrCardValidator = val;
  },

  setCcType(state, val) {
    state.editProfileForm.ccType = val;
  },

  setCcCvLength(state, val) {
    state.editProfileForm.ccCvLength = val;
  },

  setCcLength(state, val) {
    state.editProfileForm.ccLength = val;
  },

  setCcMask(state, val) {
    state.editProfileForm.ccMask = val;
  },

  setCvcMask(state, val) {
    state.editProfileForm.cvcMask = val;
  },

  setCcSize(state, val) {
    state.editProfileForm.ccSize = val;
  },

  setCcCvLengthValidation(state, val) {
    state.profileFormValidators.cc_cvc.minLength = val;
  },

  setCcNumberValidator(state, val) {
    state.profileFormValidators.cc_number.mrCardValidator = val;
  },

  setSelectedProfileId(state, val) {
    state.selectedProfileId = val;
  },

  setValidAmazonSession(state, val) {
    state.validAmazonSession = val;
  },

  setLastSavedProfile(state, val) {
    state.lastSavedProfile = val || {};
  },

  updateProfileForSubs(state, val) {
    state.editProfileForm.update_profile_for_subs = val;
  },

  updateUseProfileForAllSubs(state, val) {
    state.editProfileForm.use_payment_for_all_subscriptions = val;
  },

  updateCcNumber(state, val) {
    state.editProfileForm.cc_number = val;
  },

  updateExpMonth(state, val) {
    state.editProfileForm.cc_exp_month = val;
  },

  updateExpYear(state, val) {
    state.editProfileForm.cc_exp_year = val;
  },

  updateCvc(state, val) {
    state.editProfileForm.cc_cvc = val;
  },

  updateIsDefault(state, val) {
    state.editProfileForm.is_default = val;
  },

  updateSameAsShipping(state, val) {
    state.editProfileForm.same_as_shipping = val;
  },

  updateEditProfileFormIsValid(state, val) {
    state.editProfileForm.formIsValid = val;
  },

  clearEditProfileForm(state) {
    state.editProfileForm = {};
  },

  clearProfileToDelete(state) {
    state.profileToDelete = {};
  },

  setAmazonBillingAgreementId(state, val) {
    state.amazonBillingAgreementId = val;
  },

  setPaymentCaptchaResponse(state, val) {
    state.paymentCaptchaResponse = val;
  },

  setAfterPayCheckoutToken(state, val) {
    state.afterPayCheckoutToken = val;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
