<template lang="pug">
  .site-message-banner(v-if="hasMessage && !isCheckoutRedesignExperiment")
    .row.color-white.text-center.xs-f-small.lg-f-medium.max-at-tweak(v-if="oneMessage" :style="{'background-color': backgroundColor(messages[0])}")
      .xs-12(v-html="messages[0].message")
    slick.sitewide-carousel(v-else ref="carousel" v-bind="carouselOptions")
      .row.color-white.text-center.xs-f-small.lg-f-medium.max-at-tweak(v-for="message in sortedMessages" :style="{'background-color': backgroundColor(message)}")
        .xs-12(v-html="message.message")

</template>

<script>
  import vueSiteMessageSvc from '../../services/vueSiteMessageSvc';

  export default {
    name: "SiteMessageBanner",

    inject: ['getObjProperty'],

    props: {
      topics: {
        type: String
      }
    },

    data() {
      return {
        carouselOptions: {
          autoplay: true,
          autoplaySpeed: 8000,
          speed: 1500,
          cssEase: 'ease-in-out',
          infinite: true,
          dots: false,
          arrows: false,
          prevArrow: false,
          nextArrow: false,
          slidesToShow: 1,
        },
      };
    },

    computed: {
      utmTopic() {
        if (this.$route.query.utm_pencil_banner_topic) {
          return this.$route.query.utm_pencil_banner_topic;
        } else if (this.$cookies.get('utm_pencil_banner_topic')) {
          return this.$cookies.get('utm_pencil_banner_topic');
        }

        return null;
      },

      isCheckoutRedesignExperiment() {
        const isExperiment = window.experiments && window.experiments['Checkout redesign Q1 2024'] === 'B';
        const visitorType = this.getObjProperty(window, 'cdata.visitorType');

        return isExperiment && visitorType == 'Return Visitor';
      },

      allTopics() {
        let topics = [];

        if (this.utmTopic) {
          topics.push(this.utmTopic);
        }

        if (this.topics) {
          let topicsArray = this.topics.split(',');

          topicsArray.forEach(t => {
            t = t.toLowerCase();
            topics.push(t);
          });
        }

        return topics.join(',');
      },

      hasMessage() {
        return !!(this.messages && this.messages.length);
      },

      oneMessage() {
        return this.messages && this.messages.length == 1;
      },

      sortedMessages() {
        if (this.messages && this.messages.length) {
          let importanceMap = {
            high: 1,
            medium: 2,
            low: 3,
          };
          let sortedMessages = [...this.messages];

          sortedMessages.sort((a, b) => {
            if (this.utmTopic) {
              if (a.topics.includes(this.utmTopic)) {
                return -1;
              }
              if (b.topics.includes(this.utmTopic)) {
                return 1;
              }
            }
            if (a.importance != b.importance) {
              return importanceMap[a.importance] - importanceMap[b.importance];
            }

            let aStart = new Date(a.start_date);
            let bStart = new Date(b.start_date);

            return +bStart - +aStart;
          });

          return sortedMessages;
        }

        return null;
      },
    },

    asyncComputed: {
      messages: {
        lazy: true,
        get() {
          if (this.allTopics) {
            return vueSiteMessageSvc.getByTopics({ topics: this.allTopics }).then(res => res.data);
          }
        }
      }
    },

    mounted() {
      if (this.$route.query.utm_pencil_banner_topic) {
        this.$cookies.set('utm_pencil_banner_topic', this.$route.query.utm_pencil_banner_topic, '2h');
      }
    },

    methods: {
      backgroundColor(message) {
        if (message.background_color) {
          return message.background_color;
        }
        return "#000";
      }
    }
  };
</script>

<style lang="stylus">
  .site-message-banner
    .row
      max-width 100%
      padding 1.2em 5%
      
    .slick-slider
      margin 0
      
      *
        vertical-align bottom

</style>
