<template lang="pug">
  .mr-input-checkbox(:class="{disabled: disabled}")
    template(v-if="label")
      label
        .mr-checkbox(:class="{ 'selected': value, disabled: disabled } ")
          input(type="checkbox" :checked="value" @change="update($event.target.checked)" :data-mr-ass="dataMrAss" :tabindex="inputTabindex" :disabled="disabled")
        span.label {{ label }}

    template(v-else)
      .mr-checkbox(:class="{ 'selected': value, disabled: disabled, 'large-purple': largeAndPurple } ")
        input(type="checkbox" :checked="value" @change="update($event.target.checked)" :data-mr-ass="dataMrAss" :tabindex="inputTabindex" :disabled="disabled")

</template>

<script>
  export default {
    name: "mrInputCheckbox",
    props: {
      value: {
        type: [Boolean, Number]
      },

      label: {
        type: String
      },

      disabled: {
        type: Boolean,
        default: false
      },

      inputTabindex: {
        type: String,
        required: false,
        default: "0"
      },

      dataMrAss: {
        type: String
      },

      largeAndPurple: {
        type: Boolean,
        default: false
      }
    },

    methods: {
      update(val) {
        this.$emit('input', val);
      }
    }
  };
</script>

<style scoped lang="stylus">
  .mr-input-checkbox
    .mr-checkbox
      vertical-align top
      position absolute
      width 21px
      height 21px
      border 1px solid #999
      background-color color-white
      border-radius 3px
      margin-right 6px

      &:focus-within
        outline brand-color-2 auto 5px

      &.disabled
        border-color ui-color-5

      input[type="checkbox"]
        position absolute
        display block
        left 0
        right 0
        top 0
        bottom 0
        padding 0
        margin 0
        font-size 19px
        line-height 19px
        width 19px
        height 19px
        opacity 0

      &.selected
        &:before
          content icon-check-nobox
          font-family fontello
          text-align center
          font-size 16px
          line-height 19px
          width 19px
          height 19px
          color color-mr-purple
          position absolute
          top 0
          left 0

        &.disabled
          &:before
            color ui-color-5

    .label
      display inline-block
      vertical-align top
      line-height 20px
      padding-left 30px

    .large-purple
      transform scale(1.3)
      border-color cta-color-1
      &.selected
        &:before
          color cta-color-1
          font-weight bold

      &:checked
        color cta-color-1
        background-color transparent

</style>