import axios from 'axios';

/**
 * This module should be used for all requests to the website server.
 *
 * It ensures that the appropriate headers are managed for csrf.
 *
 * The API is the same as axios'.
 *
 * @module mrApi
 */

/**
 * Get the csrf token cookie value, if present.
 *
 * @private
 * @returns {string} csrf token cookie value, or null if not set
 */
function getCsrfCookie() {
  const value = `; ${document.cookie}`;
  const parts = value.split('; csrf_stp=');
  if (parts.length === 2) {
    return parts.pop().split(';').shift();
  }

  return null;
}

/**
 * Get the cookie keys and value lengths as a comma delimited string.
 *
 * (Avoids logging  sensitive data to Sentry.)
 *
 * @private
 * @returns {string} a comma-delimited list of cookie keys and their lengths
 */
function getCleanDebugCookieString() {
  return (document.cookie || '')
    .split(';')
    .map(bit => bit.split('='))
    .map(pair => `${decodeURIComponent(pair[0].trim())}=${decodeURIComponent(pair[1]).trim().length}`)
    .join(', ');
}

let axiosInstance = axios.create({});

axiosInstance.interceptors.request.use(request => {
  let csrf = getCsrfCookie();

  if (!csrf && process.env.IS_BROWSER) {
    import ('@sentry/browser').then(Sentry => {
      let debug = getCleanDebugCookieString();
      // eslint-disable-next-line no-console
      console.log(`WARN: no csrf defined.  Debug:  ${debug}`);

      if (Sentry) {
        Sentry.withScope(scope => {
          scope.setExtra('csrfDebug', debug);
          scope.setExtra('reqUrl', request.url);
          scope.setTag('vuescripts', 'mrApi.js');
          Sentry.captureException(new Error('Missing CSRF'));
        });
      }
    });
  }

  request.headers['x-csrf-stp'] = csrf || 'NONE';
  request.headers['x-time-offset'] = new Date().getTimezoneOffset();

  return request;
}, error => Promise.reject(error));

const get = axiosInstance.get;
const post = axiosInstance.post;

export default {
  /**
   * Issue an http GET request to the MR server.
   *
   * @param {string} path the path
   * @param {object} [options] request options- see axios documentation
   * @returns {Promise} Promise resolved when the request returns
   */
  get,

  /**
   * Issue an http POST request to the MR server.
   *
   * @param {string} path the path
   * @param {object} [options] request options- see axios documentation
   * @returns {Promise} Promise resolved when the request returns
   */
  post
};



