export const DEFAULT_BUTTONS = [
  {
    insertInto: 'Support',
    insertAt: -2,
    button: true,
    id: "ot-sdk-btn",
    class: "ot-sdk-show-settings btn-link",
    ariaLabel: "Do Not Sell or Share My Personal Info",
    text: "Do Not Sell or Share My Personal Info",
    internalTrackEventName: 'Do Not Sell or Share My Personal Info',
  }
];

export const DEFAULT_SOCIALS = [
  { url: 'https://facebook.com/madisonreedllb', title: 'Madison Reed Facebook', icon: 'icon-facebook' },
  { url: 'https://twitter.com/madisonreedllb', title: 'Madison Reed Twitter', icon: 'icon-twitter' },
  { url: 'https://pinterest.com/madisonreedllb', title: 'Madison Reed Pinterest', icon: 'icon-pinterest' },
  { url: 'https://instagram.com/madisonreed', title: 'Madison Reed Instagram', icon: 'icon-instagram' },
  { url: 'https://youtube.com/c/Madisonreedllb', title: 'Madison Reed Youtube', icon: 'icon-youtube' },
];

export const MENS_BUTTONS = [
  {
    insertInto: 'Support',
    insertAt: -2,
    id: "ot-sdk-btn",
    class: "ot-sdk-show-settings btn-link",
    ariaLabel: "Do Not Sell or Share My Personal Info",
    text: "Do Not Sell or Share My Personal Info",
    internalTrackEventName: 'Do Not Sell or Share My Personal Info',
  }
];

export const MENS_SOCIALS = [
  { url: 'https://www.facebook.com/MadisonReedMr/', title: 'Madison Reed Mister Facebook', icon: 'icon-facebook' },
  { url: 'https://twitter.com/madisonreedmr', title: 'Madison Reed Mister Twitter', icon: 'icon-twitter' },
  { url: 'https://www.instagram.com/madisonreedmr/', title: 'Madison Reed Instagram', icon: 'icon-instagram' },
  {
    url: 'https://www.youtube.com/channel/UCA8PdmgUV5-6SX6QhcQ-yMg/',
    title: 'Madison Reed Mister YouTube',
    icon: 'icon-youtube',
  },
];
