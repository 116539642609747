import vueCustomerSvc from '../../services/vueCustomerSvc';
import vueExperimentSvc from '../../services/vueExperimentSvc';
import { getObjProperty } from '../../utilities/mrVueUtils';


export function requiresLogin(to, from, next) {
  vueCustomerSvc.getLoggedInInfo().then(async (resLoggedInInfo) => {
    const res = await vueExperimentSvc.getVariantForExperiment({
      params: {
        name: 'Account Create Flow Post Purchase'
      },
    });

    const loggedIn = getObjProperty(resLoggedInInfo, 'data.loggedIn');
    const variantKey = getObjProperty(res, 'data.variantKey');

    if (!loggedIn && variantKey !== 'B') {
      window.location = notLoggedInLocation(to.fullPath);
      return next(false);
    }
    next();
  }).catch(() => {
    window.location = notLoggedInLocation(to.fullPath);
    return next(false);
  });
}

export default [
  {
    path: '/cart', name: 'cart', component: () => {
      if (window.experiments && window.experiments['Cart Redesign 2023'] === 'B') {
        return import(/* webpackChunkName: "cac-cart-v2.1" */ '../CartV2');
      } else {
        return import(/* webpackChunkName: "cac-cart" */ '../Cart');
      }
    }
  },
  // { path: '/checkout', name: 'checkout', component: Checkout, beforeEnter: requiresLogin },
  {
    path: '/checkout', name: 'checkout', beforeEnter: requiresLogin, component: () => {
      const visitorType = getObjProperty(window, 'cdata.visitorType');
      const isExperiment = window.experiments && window.experiments['Checkout redesign Q1 2024'] === 'B';

      if (isExperiment && (visitorType == 'Net New' || visitorType == 'Return Visitor')) {
        return import(/* webpackChunkName: "cac-checkoutV2" */ './CheckoutV2');
      } else {
        return import(/* webpackChunkName: "cac-checkout" */ './Checkout');
      }

    }
  }
];

export function notLoggedInLocation(path) {
  let signInView;

  if (path.includes('checkout')) {
    signInView = '&view=create';
  }

  return `/notloggedin?next=${encodeURIComponent(path)}${signInView}`;
}