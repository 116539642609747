<template lang="pug">
  svgicon(
    :data="svgContent"
    :fill="fill"
    :original="original"
    :class="{ [$options.name]: true, 'default-size': defaultSize }"
    :aria-label="ariaLabel"
  )

</template>

<script>
  export default {
    name: "mr-icon",

    props: {
      name: {
        type: String,
        required: true,
      },
      // color: {}, DO NOT use color here. By using hex code here we are
      // hard coding the color in the vue template, we should use css variables only
      // and for that reason the color should be set via css
      fill: {
        type: Boolean,
        default: true
      },

      original: {
        type: Boolean,
        default: false
      },
    },

    data() {
      return {
        svgContent: null,
      };
    },

    computed: {
      defaultSize() {
        return !(this.$attrs.height);
      },

      ariaLabel() {
        return `${this.name} icon`;
      }
    },

    watch: {
      name: {
        handler: 'loadIcon',
        immediate: true,
      },
    },

    mounted() {
      this.loadIcon();
    },

    methods: {
      loadIcon() {
        // The `[request]` placeholder is replaced
        // by the filename of the file which is
        // loaded (e.g. `mr-icon-zoom-in.js`).
        import(/* webpackChunkName: "mr-icon-[request]" */ `../../../assets/svg-icons/${this.name}.svg`)
          .then(module => {
            this.svgContent = module.default;
          });
      },
    }

  };
</script>

<style lang="stylus">

  .mr-icon
    display inline-block
    color inherit
    vertical-align middle
    fill none
    stroke currentColor

    &.default-size
      width 1em

  .mr-fill
    fill currentColor
    stroke none

</style>